import { get } from "axios";

/**
 * @deprecated since recovery invoice from odoo
 */
export const checkInvoice = async ({ id }) => {
  let result;

  try {
    await get(`/oc_invoices/check/${id}/`);
    result = true;
  } catch (e) {
    result = false;
    /* handle error */
  }

  return result;
};

/**
 * @deprecated since recovery invoice from odoo
 */
export const getPaginateInvoicesList = async ({
  offset = 0,
  limit = 10,
} = {}) => {
  try {
    const { data, headers } = await get(`/oc_invoices/`, {
      params: { offset, limit },
    });
    return { items: data, paginControl: headers };
  } catch (e) {
    console.error(e);
    /* handle error */
  }
};

export const getPaginatedInvoicesList = async ({
  offset = 0,
  limit = 10,
} = {}) => {
  try {
    const { data, headers } = await get(`/invoices/`, {
      headers: { offset, limit },
    });
    return { items: data, paginControl: headers };
  } catch (e) {
    console.error(e);
    /* handle error */
  }
};
export const getInvoiceDetail = ({ id }) => get(`/invoices/${id}/`);

export const getInvoiceByToken = ({ token, username }) =>
  get(`/invoices/${token}/retrieve_by_token/`, { params: { username } });

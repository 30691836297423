import React from "react";

export const Form = ({ children, onSubmit }) => {
  function onInnerSubmit(event) {
    event.preventDefault();
    onSubmit(event);
  }

  return <form onSubmit={onInnerSubmit}>{children}</form>;
};
